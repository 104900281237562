import React from 'react';
import {Helmet} from 'react-helmet';
import Layout from '../../components/layout/Layout';
import {Link} from 'gatsby';
import ImageGallery from 'react-image-gallery';
import ProductPageIcon from './../../components/ProductPageIcon';
import beraterBild from '../../images/berater_04.webp'
import schiebetuer_01 from '../../images/automatische_schiebetuer_hotel_windfang_1600.webp';
import schiebetuer_thumb_01 from '../../images/automatische_schiebetuer_hotel_windfang_600.webp';
import schiebetuer_02 from '../../images/automatische_schiebetuer_hotel_empfang_1600.webp';
import schiebetuer_thumb_02 from '../../images/automatische_schiebetuer_hotel_empfang_600.webp';
import schiebetuer_03 from '../../images/automatische_schiebetuer_gastronomie_fruehstueck_1600.webp';
import schiebetuer_thumb_03 from '../../images/automatische_schiebetuer_gastronomie_fruehstueck_600.webp';
import schiebetuer_04 from '../../images/automatische_schiebetuer_hotel_schmal_1600.webp';
import schiebetuer_thumb_04 from '../../images/automatische_schiebetuer_hotel_schmal_600.webp';
import schiebetuer_05 from '../../images/automatische_schiebetuer_oberlicht_hotel_1600.webp';
import schiebetuer_thumb_05 from '../../images/automatische_schiebetuer_oberlicht_hotel_600.webp';
import schiebetuer_06 from '../../images/automatische_schiebetuer_gastronomie_wc_1600.webp';
import schiebetuer_thumb_06 from '../../images/automatische_schiebetuer_gastronomie_wc_600.webp';
import person from '../../images/automatische_schiebetuer_gastronomie_inhaberin_1600.webp';
import schiebetuer_gastronomie_kontaktlos from '../../images/automatische_schiebetuer_gastronomie_kontaktlos_800.webp';
import speiseraum from '../../images/automatische_schiebetuer_gastronomie_speiseraum_800.webp';
import schiebetuer_hotel_02_mp4 from '../../videos/schiebetuer_hotel_02.mp4';
import schiebetuer_hotel_02_webm from '../../videos/schiebetuer_hotel_02.webm';
import {trackScreenView} from '../../functions/analytics';
import phone from '../../svg/phone.svg';

const Apotheke = () => {

  const carouselConfig = {
    showThumbnails: true,
    showFullscreenButton: false,
    showPlayButton: false,
    showBullets: false,
    autoPlay: false,
    showNav: false,
    slideInterval: 3000,
    slideDuration: 600,
  }

  const context = {
features: [
      //'autschr',
      'barrierefreiheit',
      //'brandschutz',
      //'breakOut',
      'personenfrequenz',
      //'din18650',
      'energieeinsparung',
      'notStrom',
      'einbruchhemmung',
      'design',
      //'platzsparend',
      //'transparenz',
      'zutrittskontrolle',
      'fluchtweg',
      'modernisierung',
    ],
    carouselImages: [
      {
        original: schiebetuer_01,
        thumbnail: schiebetuer_thumb_01,
        originalAlt: 'Zwei automatische Teleskop-Schiebetüren im Hotel als Windfang',
        originalTitle: 'Zwei automatische Teleskop-Schiebetüren im Hotel als Windfang',
        thumbnailTitle: 'Zwei automatische Teleskop-Schiebetüren im Hotel als Windfang',
      },
      {
        original: schiebetuer_02,
        thumbnail: schiebetuer_thumb_02,
        originalAlt: 'Hotel Eingangsbereich mit eleganter schwarzer automatischer Schiebetür',
        originalTitle: 'Hotel Eingangsbereich mit eleganter schwarzer automatischer Schiebetür',
        thumbnailTitle: 'Hotel Eingangsbereich mit eleganter schwarzer automatischer Schiebetür',
      },
      {
        original: schiebetuer_03,
        thumbnail: schiebetuer_thumb_03,
        originalAlt: 'Frühstücksraum im Hotel mit einflügligliger elektrischer Schiebtür',
        originalTitle: 'Frühstücksraum im Hotel mit einflügligliger elektrischer Schiebtür',
        thumbnailTitle: 'Frühstücksraum im Hotel mit einflügligliger elektrischer Schiebtür',
      },
      {
        original: schiebetuer_04,
        thumbnail: schiebetuer_thumb_04,
        originalAlt: 'Automatiktür in schmalem Hoteleingang mit hohem Oberlicht',
        originalTitle: 'Automatiktür in schmalem Hoteleingang mit hohem Oberlicht',
        thumbnailTitle: 'Automatiktür in schmalem Hoteleingang mit hohem Oberlicht',
      },
      {
        original: schiebetuer_05,
        thumbnail: schiebetuer_thumb_05,
        originalAlt: 'Automatische Schiebetür mit seitlicher Verglasung als Zugang zur Außenbereich des Hotels',
        originalTitle: 'Automatische Schiebetür mit seitlicher Verglasung als Zugang zur Außenbereich des Hotels',
        thumbnailTitle: 'Automatische Schiebetür mit seitlicher Verglasung als Zugang zur Außenbereich des Hotels',
      },
      {
        original: schiebetuer_06,
        thumbnail: schiebetuer_thumb_06,
        originalAlt: 'Automatische Schiebetür Gastro WC kontaktlos',
        originalTitle: 'Automatische Schiebetür Gastro WC kontaktlos',
        thumbnailTitle: 'Automatische Schiebetür Gastro WC kontaktlos',
      },
    ],
    configurator: true,
  }

  return (
    <div className="application">
      <Helmet>
        <title>Automatische Schiebetüren für Gastronomie, Restaurants und Hotels | Automatiktür24</title>
        <meta name="description" content="Automatische elektrische Schiebetüren für Gastronomie, Cafés und Restaurants.
        Barrierefreier Eingang in der Gastro, elegante Glasschiebetür, platzsparend" />
        <meta charSet="utf-8" />
        <html lang="de" />
      </Helmet>
      <Layout>

        <section className="pt-10">
          <div className="container mx-auto px-8 lg:flex">
            <div className="w-full">
              <p className="text-lg lg:text-xl mb-3 font-light">
                Automatiktüren für die Gastronomie: eine Technik mit vielen Vorteilen
              </p>
              <h1 className="text-2xl lg:text-3xl xl:text-5xl font-bold leading-none">
                Automatische Schiebetüren für Gastronomie, Restaurants und Hotels
              </h1>
            </div>
          </div>


          <div className="container mx-auto px-8 lg:flex">

            <div className="lg:w-1/2">
              <div className="pb-10 mt-12">
                <ImageGallery items={context.carouselImages} {...carouselConfig}/>
              </div>
            </div>

            <div className="lg:w-1/2 lg:pl-5 xl:pl-10 md:flex lg:flex">

              <div className="pb-10 md:w-1/2 lg:w-2/3">
                <p className="text-lg lg:text-xl font-light mt-12 mb-4">
                  Vorteile und Möglichkeiten <br/> auf einen Blick
                </p>
                <ul>
                  {context.features.map(feature => (
                    <li key={feature}>
                      <ProductPageIcon attribute={feature} />
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:w-1/2 lg:w-1/3">
                {(context.configurator === true) ?
                  <div className="lg:w-60 mb-6">
                    <Link to="/automatiktuer-konfigurator/"
                          className="bg-blue-500 hover:bg-blue-600 text-white hover:text-white
                          py-4 text-center block rounded shadow-xl"> Automatiktür für Gastronomie konfigurieren</Link>
                  </div>
                  : ''
                }
                <div className="lg:w-60 mb-6">
                  <Link to="/kontakt/" className="bg-gray-400 hover:bg-gray-500 text-white hover:text-white py-4 text-center block rounded shadow-xl">Kontakt aufnehmen</Link>
                </div>

                <div className="w-48 rounded overflow-hidden shadow-xl">
                  <img className="w-full" src={beraterBild} alt="Angebot einfordern" />
                  <div className="px-6 py-4">
                    <div className="font-bold text-xl mb-2">Max Brunner</div>
                    <p className="text-gray-700 text-sm">
                      Individuelle Beratung
                      <br/>
                      Tel. <a href="tel:+4975245092939">07524 / 509 29 39</a>
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div className="container mt-20 mb-8 mx-auto px-8">
            <h2 className="lg:text-2xl font-semibold">
              Automatiktüren für Gastronomie und Hotellerie
            </h2>
            <p className="text-xl font-light">Vielseitige Einsatzmöglichkeiten in Restaurant, Wirtshaus, Café oder Hotel</p>
          </div>

          <div className="container mx-auto px-8 lg:flex">
            <div className="lg:w-1/2">
              <p>Sie öffnet Ihren Gästen den Zugang, wertet den Eingangsbereich auf und macht Ihren Mitarbeitern den Weg frei: eine Automatiktür.<br/>
                In der Gastronomie gibt es für diese Technik zahlreiche Einsatzbereiche.
                Erfahren Sie hier mehr über die Möglichkeiten oder <a href="/automatiktuer-konfigurator/">konfigurieren Sie direkt Ihre Schiebetür</a> in unserem Online-Konfigurator.</p>

              <p className="mt-6">Elektrische Schiebetüren: Ästhetik trifft Funktion
                Das Grundprinzip einer automatischen Schiebetür ist bekannt: Sobald eine Person hindurchschreiten möchte,
                öffnen sich die Türen automatisch. Gerade im Bereich der Gastronomie sind die Ansprüche an Ästhetik und Funktionalität jedoch höher.
              </p>

              <p className="mt-6">Optisch lassen sich die Automatiktüren zum Beispiel im Detail auf das Ambiente abstimmen.
                Zu einem rustikalen Landgasthof passen Türen mit Holzvertäfelung.
                Ein modernes Gourmetrestaurant begrüßt seine Gäste mit geradlinigen, leise schließenden Glaselementen.
                Sogar für denkmalgeschützte Gebäude gibt es passende Automatiktüren, die Moderne und Klassik miteinander in Einklang bringen.
              </p>

              <p className="mt-6">In Bezug auf die Nutzung bieten Sie je nach Einsatzort viele verschiedene Vorteile:</p>

              <div className="border-l-4 border-r-4 border-gray-300 rounded-2xl py-1 mt-4">
                <ol className="list-decimal m-3 ml-12 text-gray-800">
                  <li className="p-1">Der erste Eindruck wirkt elegant und hochwertig.</li>
                  <li className="p-1">Thermisch getrennte Profile sorgen für hohe Wärmedämmung und sinkende Energiekosten.</li>
                  <li className="p-1">Der gesamte Eingangsbereich gewinnt durch die großzügigen Glastüren an Helligkeit.</li>
                  <li className="p-1">Sie vermeiden Platzprobleme, die bei Flügeltüren entstehen können.</li>
                </ol>
              </div>
            </div>

            <div className="lg:w-1/2">
              <div className="flex flex-col lg:mx-6 mt-8 lg:mt-0 max-w-500">
                <div
                  className="relative overflow-hidden flex-none w-full h-64 md:h-80 rounded-t-xl mx-auto">
                  <img className="absolute object-cover object-top w-full h-full" src={person} alt="" />
                </div>
                <div className="p-4 md:p-8 text-center md:text-left space-y-4 bg-gray-100 rounded-b-xl text-base">
                  <blockquote>
                    <p className="font-light italic text-yellow-700">
                      &#8220;Wir setzten komplett auf Automatiktüren. Speziell im Durchgang zum Außenbereich ist die
                      automatische Öffnung besonders praktisch - egal ob Kunden mit Kinderwagen oder
                      Mitarbeiter mit Tabletts in der Hand.&#8221;
                    </p>
                  </blockquote>
                  <figcaption className="font-medium">
                    <div className="text-gray-400">
                      Kundin aus der Gastronomie
                    </div>
                  </figcaption>
                </div>
              </div>

            </div>
          </div>



          <div className="container mt-20 mb-8 mx-auto px-8">
            <h2 className="lg:text-2xl font-semibold">
              Automatische Schiebetür im Hotel oder Restaurant
            </h2>
            <p className="text-xl font-light">Barrierefrei. Komfortabel. Hygienisch. Modern.</p>
          </div>

          <div className="container mx-auto px-8 lg:flex">
            <div className="lg:w-1/2 order-2">
              <p>Auf technischer Seite muss eine moderne Automatiktür ebenfalls überzeugen.
                Herausforderungen können aus engen Laufwegen oder dem baulichen Brandschutz und Einbruchschutz resultieren.
                Hier ist es wichtig, die Lösung dem eigenen Bedarf anzupassen.
                Dann kann eine Automatiktür sogar viele Probleme lösen,
                die mit manuellen Türen nicht lösbar sind – etwa barrierefreie Rettungswege.</p>
              <p className="mt-6">Gut zu wissen: Der Einbau einer automatischen Schiebetür ist bei Neubauten,
                Modernisierungen und als Einzelprojekt möglich.
              </p>
              <p className="mt-6"><b>Kontaktlos vom Eingang bis zur Kundentoilette</b><br/>
                Der wohl entscheidende Vorzug einer automatischen Schiebetür für Restaurants und Cafés ist der Zugewinn an Komfort.
                Kein Drücken oder Ziehen, keine verschlossenen Türen: Ihre Gäste gelangen mühelos von einem Bereich in den nächsten.
                Daraus ergeben sich je nach Einsatzort weitere Vorteile. Automatiktüren vor den Kundentoiletten sorgen für mehr Hygiene.
                Im Gastraum werden die Geräusche durch Türen auf ein Minimum reduziert.</p>
            </div>
            <div className="lg:w-1/2">
              <div className="flex flex-col md:flex-row lg:flex-col xl:flex-row lg:mx-6 mt-8 lg:mt-0 max-w-400 md:max-w-full">
                <div
                  className="relative overflow-hidden flex-none w-full md:w-80 lg:w-full xl:w-80 h-96 xl:h-112 rounded-t-xl
                  md:rounded-none md:rounded-l-xl lg:rounded-none lg:rounded-t-xl xl:rounded-none xl:rounded-l-xl mx-auto border-2 border-yellow-600">
                  <img className="absolute object-cover object-top w-full h-full" src={speiseraum} alt="Automatiktür Gastronomie - Automatische Schiebetür im Speiseraum" />
                </div>
                <div className="p-4 md:p-8 text-left space-y-4 bg-yellow-600 rounded-b-xl md:rounded-none md:rounded-r-xl
                lg:rounded-none lg:rounded-b-xl xl:rounded-none xl:rounded-r-xl">
                  <blockquote>
                    <p className="text-base font-bold text-white">
                      Automatiktür im Speiseraum
                    </p>
                    <p className="text-md font-light text-white mt-4">
                      Auch mit wenig Platz findet sich eine passende Lösung.
                      Eine leise Automatiktür bietet barrierefreien Zugang ohne störenden Türflügel.
                      Optional mit automatischer Zeitsteuerung.
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>


          <div className="container mt-20 mb-8 mx-auto px-8">
            <h2 className="lg:text-2xl font-semibold">
              Einsatzbereiche für Automatiktüren in der Gastronomie
            </h2>
            <p className="text-xl font-light">Terasse, Küche, Abtrennung, Toilette und mehr</p>
          </div>

          <div className="container mx-auto px-8 lg:flex">
            <div className="lg:w-1/2 order-1">
              <p>Auch für das Servicepersonal erhöht sich der Komfort.
                Eine automatische Schiebetür in der Küche öffnet sich von selbst – es muss niemand Hand anlegen.
                So fällt es leicht, mit mehreren Tellern von der Küche in den Gastraum zu gelangen.
                Hier bieten sich Ellenbogen- oder Fußtaster als Auslöser an.
              </p>

              <p className="mt-6">Eine elektrische Schiebetür sorgt außerdem für Barrierefreiheit im gesamten Haus.
                Menschen mit Einschränkung des Gehvermögens sowie deren Begleitung wissen es zu schätzen, ohne Hindernisse zum Platz zu kommen.
              </p>

              <p className="mt-6">
                Einsatzbereiche für Automatiktüren in der Gastronomie
                In der Gastronomie gibt es viele Möglichkeiten, eine Automatiktür gewinnbringen einzusetzen.
                Naheliegend ist eine Installation im Eingang, wo Gäste komfortabel ins Haus gelangen.
                Aber auch in folgenden Bereichen sind elektrische Schiebetüren sinnvoll:
              </p>

              <ul className="list-disc pl-6 mt-3">
                <li>Am Übergang zu Terrassen und Außenbereichen</li>
                <li>In der Küche</li>
                <li>Am Zugang zu den Kundentoiletten</li>
                <li>Als Abtrennung zwischen Restaurant und Bar</li>
              </ul>

              <p className="mt-6">
                So vielfältig wie die Einsatzbereiche sind auch die Betriebe, die von Automatiktüren profitieren.
                Neben dem klassischen Speiselokal sind hier Hotels, Bistros, Wirtshäuser und Systemgaststätten zu nennen.
              </p>


            </div>
            <div className="lg:w-1/2 order-2">
              <div className="flex flex-col md:flex-row lg:flex-col xl:flex-row lg:mx-6 mt-8 lg:mt-0 max-w-400 md:max-w-full">
                <div
                  className="relative overflow-hidden flex-none w-full md:w-80 lg:w-full xl:w-80 h-96 xl:h-112 rounded-t-xl
                  md:rounded-none md:rounded-l-xl lg:rounded-none lg:rounded-t-xl xl:rounded-none xl:rounded-l-xl mx-auto border-2 border-blue-700">
                  <img className="absolute object-cover object-top w-full h-full" src={schiebetuer_gastronomie_kontaktlos} alt="" />
                </div>
                <div className="p-4 md:p-8 text-left space-y-4 bg-blue-700 rounded-b-xl md:rounded-none md:rounded-r-xl
                lg:rounded-none lg:rounded-b-xl xl:rounded-none xl:rounded-r-xl">
                  <blockquote>
                    <p className="text-base font-bold text-white">
                      Flexibel im Service
                    </p>
                    <p className="text-md font-light text-white mt-4">
                      Von der Küche in den Gastbereich, von der Theke auf die Terrasse oder zwischen zwei abgetrennte Bereichen hin und her - dank Automatiktür geht das auch voll beladen.<br/><br/>
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>



          <div className="container mt-14 mb-8 mx-auto px-8">
            <h2 className="lg:text-2xl font-semibold">
              Hotel & Gastronomie mit Automatiktür
            </h2>
            <p className="text-xl font-light">Ein guter erster Eindruck dank elektrischer Schiebetür</p>
          </div>

          <div className="container mx-auto px-8 lg:flex">
            <div className="lg:w-1/2 order-2">
              <div>
                <div className="pb-3 font-bold">Vorteile auf einen Blick:</div>
                <ul className="list-disc pl-6">
                  <li>Barrierefreiheit</li>
                  <li>hygienische Lösung</li>
                  <li>moderne Optik</li>
                  <li>Durchlässigkeit von Sonnenlicht</li>
                  <li>energieeffiziente Lösung</li>
                  <li>geräuscharme Möglichkeit des Öffnens</li>
                  <li>leichte Bauweise</li>
                </ul>
              </div>

              <div className="mt-6">
                <div className="pb-3 font-bold">Konfigurieren Sie Ihre Tür nach Ihrem Bedarf</div>
                <p>
                  Die entscheidende Frage ist: Passt eine Automatiktür zu Ihrem Betrieb?
                  Fast immer lautet die Antwort: Ja!
                  Im Detail sollten Einzelheiten wie automatische Sensoren oder zusätzliche Ellenbogentaster in Betracht gezogen werden.
                  Auch eine Zutrittskontrolle, etwa mit PIN oder Zugangskarte, ist möglich.
                  So verschaffen Sie Ihren Mitarbeitern Zugang zu den Personalräumen oder regulieren den Eingang zu Bereichen,
                  für die Gäste eine separate Eintrittsgebühr zahlen – und das ohne den zusätzlichen Einsatz von Personal.
                </p>
              </div>

              <p className="mt-6">
                Entdecken Sie jetzt alle Möglichkeiten einer automatischen Schiebetür für die Gastronomie
                in unserem <Link to="/automatiktuer-konfigurator/"> Automatiktür-Konfigurator</Link> oder lassen Sie sich persönlich von unserem Service-Team beraten.
              </p>

              <div className="mt-12 w-72">
                <div className="border border-blue-500 py-3 px-4 justify-center flex rounded shadow-xl h-12 mb-6">
                  <a className="text-sm flex" href="tel:+4975245092939" onClick={() => trackScreenView("Phone Apotheke")}><img src={phone} alt="Telefon" className="text-blue-600 h-5"/> 07524 / 509 29 39</a>
                </div>

                <div className="mb-6">
                  <Link to="/automatiktuer-konfigurator/"
                        className="border border-blue-500 py-3 px-4 text-center block rounded shadow-xl"> Automatiktür online konfigurieren</Link>
                </div>
              </div>

            </div>
            <div className="lg:w-1/2 order-1">
              <div className="flex flex-col lg:mx-6 mt-8 lg:mt-0 max-w-500">
                <div
                  className="relative overflow-hidden flex-none w-full h-52 sm:h-64 rounded-t-xl mx-auto border-2 border-red-900">
                  <video autoPlay muted loop width="500">
                    <source src={schiebetuer_hotel_02_webm} type="video/webm" />
                    <source src={schiebetuer_hotel_02_mp4} type="video/mp4" />
                  </video>
                </div>
                <div className="p-4 md:p-8 text-left space-y-4 bg-red-900 rounded-b-xl">
                  <blockquote>
                    <p className="text-base font-bold text-white">
                      Automatiktür24 Komplettpaket
                    </p>
                    <p className="text-md font-light text-white mt-4">
                      Planung, Montage, Wartung und Service.<br/>
                      Wir sind Ihr Rundum-Sorglos-Partner. Egal ob Neu- oder Umbau, Modernisierung der bestehenden Anlage.<br/><br/>
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>

        </section>

      </Layout>
    </div>
  );
}

export default Apotheke

